import CoursesFrontPage from '../../Courses/CoursesFrontPage';
import FrontPageHeader from '../../Headers/FrontPageHeader';
import './home.css';
import AboutCompany from '../../About/AboutCompany';
import { Faqs } from '../../Faqs/Faqs';

const HomePage = () => {
  document.title = 'Badenymfene Babysvømming';
  return (
    <div className='home'>
      <FrontPageHeader />
      <div className="content">
        <CoursesFrontPage />
        <Faqs />
        <AboutCompany />
      </div>
    </div>
  );
}

export default HomePage;
