import './profile.css';
import { useContext, useEffect, useState } from 'react';
import TitleWithSeparator from '../../Separator/TitleWithSeparator';
import { LoggedInUserContext } from '../../../context';
import { AdminProfileNav } from '../../Navbar/AdminProfileNav';
import PersonCard from '../../Cards/PersonCard';
import { fetchProfileFromParentId } from '../../../functions/fetch/profile';
import { NotLoggedIn } from '../../Errors/NotLoggedIn';
import { CourseHistoryTable } from '../../Courses/CourseHistoryTable/CourseHistoryTable';
import { weekdayHelper } from '../../../functions/helpers';

export const ProfilePage = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [populatedProfile, setPopulatedProfile] = useState(null);

  document.title = 'Min profil - Badenymfene Babysvømming';

  useEffect(() => {
    const fetchProfile = async () => {
      if (!loggedInUser) return;
      const profiles = await fetchProfileFromParentId(loggedInUser._id);
      setPopulatedProfile(profiles[0]);
    }
    fetchProfile();
  }, [loggedInUser]);

  if (!loggedInUser) return <div className='content-margined'><NotLoggedIn /></div>;

  return (
    <div className="profile-page">
      {/* {loggedInUser.roles.includes('admin') &&
        <AdminProfileNav />
      } */}
      <div className="content-margined">
        <TitleWithSeparator title="Min profil" />
        <div className="persons">
          <div className="parents">
            <h3>Foresatte</h3>
            <div className="person-cards">
              {populatedProfile && populatedProfile.parents.map((parent) => <PersonCard key={parent._id} user={parent}>
                <h4>{parent.firstName} {parent.lastName}</h4>
                <p>{parent.email}</p>
                <p>{parent.phone.substring(2)}</p>
              </PersonCard>)}
            </div>
          </div>
          <div className="children">
            <h3>Barn</h3>
            <div className="person-cards">
              {populatedProfile && populatedProfile.children.map((child) => {
                const birthdate = new Date(child.birthdate);
                const today = new Date();
                const diffDate = new Date(today.getTime() - birthdate.getTime());
                const diffYears = diffDate.getUTCFullYear() - 1970;
                const diffMonths = diffDate.getUTCMonth();
                const diffDays = diffDate.getUTCDate();
                // const ageInMonths = diffMonths < 12 ? diffMonths : null;
                // const ageInDays = diffDays < today.getUTCDate() ? diffDays : null;
                let ageStr = '';
                if (diffYears) ageStr += `${diffYears} år`;
                if (diffMonths && diffYears === 0) ageStr += ` ${diffMonths} mnd`;

                return <PersonCard key={child._id} user={child}>
                <h4>{child.firstName} {child.lastName}</h4>
                <p>{ageStr} ({new Date(child.birthdate).toLocaleDateString()})</p>
              </PersonCard>})}
            </div>
          </div>
        </div>
        <div className="course-history">
          <h3>Kurshistorikk</h3>
          <CourseHistoryTable>
            {populatedProfile && populatedProfile.children.map((child) => {
              if (!child.coursesHistory) return;
              return child.coursesHistory.map((course) => {
                let status = 'Påmeldt';
                const now = Date.now();
                if (now >= new Date(course.dates[0]).getTime() && now <= new Date(course.dates[course.dates.length - 1]).getTime()) {
                  status = 'Pågår';
                } else if (now >= new Date(course.dates[course.dates.length - 1]).getTime()) {
                  status = 'Fullført';
                }
                return <tr key={course._id}>
                  <td></td>
                  <td>{child.firstName}</td>
                  <td>{course.place.name}</td>
                  <td>{course.participantsAge}</td>
                  <td>{course.experienceLevel}</td>
                  <td>{course.timeFrom} - {course.timeTo}</td>
                  <td>{weekdayHelper(new Date(course.dates[0]).getDay())}</td>
                  <td>{new Date(course.dates[0]).toLocaleDateString()}</td>
                  <td>{course.dates.length + 1}</td>
                  <td>{course.price}</td>
                  <td>{status}</td>
                </tr>
              })
            })}
          </CourseHistoryTable>
        </div>
      </div>
    </div>
  );
}

