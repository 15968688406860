import { api } from "../../config";
import { fetchWithVippsToken } from "../auth";

export const fetchMyProfile = async () => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/profiles`);
    const data = await res.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}

export const fetchProfile = async (profileId) => {
  if (!profileId || profileId.length !== 24) return;
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/profiles/${profileId}`);
    const data = await res.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}

export const addProfile = async (newProfile) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/profiles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newProfile)
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const editProfile = async (editedProfile) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/profiles/${editedProfile._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editedProfile)
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const deleteProfile = async (profileId) => {
  if (!profileId || profileId.length !== 24) return;
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/profiles/${profileId}`, {
      method: 'DELETE'
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const fetchMyChildren = async () => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/profiles/children`);
    const data = await res.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}

export const getProfilesFromChildrenIds = async (childrenIds) => {
  if (!childrenIds || !childrenIds.length) return;
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/profiles?pop=true&cId=${childrenIds.join(',')}`, {
      method: 'GET',
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const fetchProfileFromParentId = async (parentId) => {
  if (!parentId || parentId.length !== 24) return;
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/profiles?pop=true&pId=${parentId}`, {
      method: 'GET',
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}
